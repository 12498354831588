<template>
  <div>

    <van-nav-bar
        title="订单详情"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <van-loading style="margin-top: 20%"  v-show="loading" type="spinner" size="24px" color="#0094ff"  vertical>加载中...</van-loading>

    <van-cell-group title="运单详情"   v-if="!loading" >
        <van-cell v-for="(wms,i) in orderListDetail"  >
          <van-row type="flex" justify="space-between" style="padding-bottom: 0.5%">
            <van-col span="19">{{i+1}} -{{wms.goodsName}} </van-col>
          </van-row>
          <van-row type="flex" justify="space-between" >
            <van-col > {{wms.expressNo}}</van-col>
            <van-col > {{wms.transportType}}-{{wms.postType}}-{{wms.expressType}}</van-col>
          </van-row>
          <van-row  type="flex" justify="space-between" >
            <van-col > {{wms.removePackage}}包装 - {{wms.packageRemark}}</van-col>
            <van-col > {{wms.expressApplyTime}}</van-col>
          </van-row>
          <van-row  type="flex" justify="space-between" >
            <van-col > {{wms.receiveAddress}}</van-col>
          </van-row>


        </van-cell>

    </van-cell-group>
    <van-cell-group title="支付方式" style="padding-bottom: 3rem">
      <van-cell>
        付款方式，RMB 优先谢谢🙏 您的支持！！ 🙇‍♀️🙇‍♀️
        <br>
        <van-icon name="wechat-pay" size="15" color="green" />微信支付:
        <br>
        微信直接转
        <br>
        <van-icon name="credit-pay" size="15"/>quick pay:
        <br>
        9176804988 kun zheng
        <van-icon name="completed"   @click="copyActiveCode($event,'9176804988 kun zheng' )">复制</van-icon>
        <br>
        <van-icon name="alipay" size="15" color="blue" /> 支付宝:
        <br>1-9176804988*坤
        <van-icon name="completed"   @click="copyActiveCode($event,'1-9176804988*坤' )">复制</van-icon>
        <br>
        付款后请截图
      </van-cell>

    </van-cell-group>

    <!-- 开启底部安全区适配 -->
        <van-number-keyboard safe-area-inset-bottom />

  </div>
</template>

<script>
import {Checkbox, CheckboxGroup, Card, SubmitBar, Toast, Form, Button} from 'vant';
import { queryOrderListDetail } from '@/api/order/order';
import Clipboard from "clipboard";

export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Button.name]: Button,
    [Form.name]: Form
  },

  data() {
    return {
      loading:true,
      submitLoading:false,
      content: '',
      customerCode:'',
      showPicker: false,
      transportType: '海运',
      postType: '拼邮',
      showPickerPostType:false,
      receiveName: '',
      receiveAddress: '',
      receiveAddressValue: '',
      showReceiveAddressType:false,
      truncationCommonPackageList:[],
      truncationSpecialPackageList:[],
      orderListDetail:[],
      page:1,
      finished: false,
      error: false,
      orderNo:'',
    };
  },

  computed: {
  },

  methods: {
    onClickLeft() {
      this.$router.go(-1);//返回上一层
    },
    initOrderDetail() {
      const sendParam={
        orderNo:this.orderNo,
        customerCode:this.customerCode
      }
      queryOrderListDetail(sendParam).then(res=>{
        this.loading=false;
        if( res.code='200' ){
           this.orderListDetail=res.data.orderListDetail
           if(res.data.orderListDetail.length<20){
             this.finished = true;
           }else{
            this.page=this.page+1;
           }
        }
      }) .catch(() => {
        this.error = true;
        // on cancel
      });
    },
    copyActiveCode(e, text) {
      const clipboard = new Clipboard(e.target, { text: () => text })
      clipboard.on('success', () => {
        Toast({
          message: '复制成功'
        })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        // 不支持复制
        Toast({
          message: '该浏览器不支持自动复制'
        })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    },

    sorry(){
      Toast({
        message: '即将上线'
      })
    }

  },
  created() {


    this.orderNo = this.$route.params.orderNo


    let token = this.$route.query.token
    if(!token||token==''){
      token=JSON.parse(localStorage.getItem("loginToken")|| '{}').loginToken
    }
    if(!token||token==''){
      this.$router.push({ name: 'guide' })
    }else{
      /**
       * 登录 token 保存
       *
       * */
      localStorage.setItem("loginToken",JSON.stringify({loginToken:token}))

      this.customerCode = token
    }
    this.initOrderDetail()

  }
};
</script>

<style lang="less">
.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}
</style>
